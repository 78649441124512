import { AlertTriangle } from '@drift-labs/icons';
import { Typo, Tag } from '@drift-labs/react';
import Tooltip from '../Tooltip/Tooltip';

export const HighRiskChip = (props: {
	className?: string;
	iconOnly?: boolean;
}) => {
	return (
		<Tag color="warning">
			{props.iconOnly ? (
				<Tooltip content="High Risk">
					<AlertTriangle />
				</Tooltip>
			) : (
				<div className="flex items-end gap-1">
					<AlertTriangle />
					<Typo.B6>High Risk</Typo.B6>
				</div>
			)}
		</Tag>
	);
};
