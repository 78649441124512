import { GET_API_ROUTE } from 'src/constants/api';
import { SerializedVaultSnapshot } from 'src/db/schema';
import useSWR from 'swr';

export type VaultSnapshotEssentials = Pick<
	SerializedVaultSnapshot,
	| 'ts'
	| 'slot'
	| 'oraclePrice'
	| 'totalAccountBaseValue'
	| 'totalAccountQuoteValue'
	| 'netDeposits'
	| 'netQuoteDeposits'
	| 'totalShares'
>;

const DEFAULT_SNAPSHOT: VaultSnapshotEssentials[] = [];

export const useVaultSnapshotHistory = (vaultPubkey?: string) => {
	const { data: vaultSnapshots, isLoading: isVaultSnapshotsLoading } = useSWR<
		VaultSnapshotEssentials[]
	>(
		vaultPubkey
			? `${GET_API_ROUTE('vault-snapshots')}?vault=${vaultPubkey}`
			: null,
		{
			fallbackData: DEFAULT_SNAPSHOT,
		}
	);

	return {
		vaultSnapshots,
		isVaultSnapshotsLoading,
	};
};
