'use client';

import { useState } from 'react';
import { ButtonGroup } from 'src/components/Button';
import { GraphType, Period } from '../VaultGraphs';
import { VaultHistoryGraph } from './VaultHistoryGraph';
import {
	SHOW_VAULTS_APY_GRAPH,
	SHOW_VAULTS_PNL_GRAPH,
	SHOW_VAULTS_ROI_GRAPH,
} from '../../../../constants/constants';

const GRAPH_OPTIONS: { label: string; value: GraphType }[] = [
	{
		label: 'Share Price',
		value: 'sharePrice',
	},
	{
		label: 'Vault Balance',
		value: 'balance',
	},
];

if (SHOW_VAULTS_PNL_GRAPH) {
	// Show P&L graph first, if it's enabled
	GRAPH_OPTIONS.unshift({
		label: 'P&L',
		value: 'pnl',
	});
}

if (SHOW_VAULTS_APY_GRAPH) {
	GRAPH_OPTIONS.unshift({
		label: 'APY',
		value: 'apy',
	});
}

if (SHOW_VAULTS_ROI_GRAPH) {
	GRAPH_OPTIONS.unshift({
		label: 'ROI',
		value: 'roi',
	});
}

const DEFAULT_GRAPH_OPTION = GRAPH_OPTIONS[0].value;

const PERIOD_OPTIONS: { label: string; value: Period }[] = [
	{
		label: '7D',
		value: '7d',
	},
	{
		label: '30D',
		value: '30d',
	},
	{
		label: '90D',
		value: '90d',
	},
];

export const VaultPerformanceBreakdownForSidebar = (props: {
	vaultPubkey: string;
	depositAssetMarketIndex: number;
}) => {
	const [selectedGraph, setSelectedGraph] = useState(DEFAULT_GRAPH_OPTION);
	const [selectedPeriod, setSelectedPeriod] = useState(PERIOD_OPTIONS[0].value);

	return (
		<div className="flex flex-col w-full sm:rounded">
			<div className="flex flex-wrap items-center justify-between gap-2">
				<ButtonGroup.Segmented
					options={GRAPH_OPTIONS}
					selected={selectedGraph}
					selectAction={(value) => setSelectedGraph(value as GraphType)}
					size="MEDIUM"
				/>
			</div>
			<VaultHistoryGraph
				vaultPubkey={props.vaultPubkey}
				depositAssetMarketIndex={props.depositAssetMarketIndex}
				graphType={selectedGraph}
				period={selectedPeriod}
			/>
			<ButtonGroup.Segmented
				options={PERIOD_OPTIONS}
				selected={selectedPeriod}
				selectAction={(value) => setSelectedPeriod(value as Period)}
				size="MEDIUM"
				className="w-full"
				optionClassName="w-full"
			/>
		</div>
	);
};
export const VaultPerformanceBreakdownForViewVaultPage = (props: {
	vaultPubkey: string;
	depositAssetMarketIndex: number;
}) => {
	const [selectedGraph, setSelectedGraph] = useState(DEFAULT_GRAPH_OPTION);
	const [selectedPeriod, setSelectedPeriod] = useState(PERIOD_OPTIONS[2].value);

	return (
		<div className="flex flex-col sm:w-full sm:rounded sm:mx-0">
			<div className="flex items-center justify-between gap-2 flex-wrap">
				<ButtonGroup.Segmented
					options={GRAPH_OPTIONS}
					selected={selectedGraph}
					selectAction={(value) => setSelectedGraph(value as GraphType)}
					size="MEDIUM"
				/>
				<ButtonGroup.Segmented
					options={PERIOD_OPTIONS}
					selected={selectedPeriod}
					selectAction={(value) => setSelectedPeriod(value as Period)}
					size="MEDIUM"
					optionClassName="w-full"
				/>
			</div>
			<VaultHistoryGraph
				vaultPubkey={props.vaultPubkey}
				depositAssetMarketIndex={props.depositAssetMarketIndex}
				graphType={selectedGraph}
				period={selectedPeriod}
			/>
		</div>
	);
};
