import { Tag } from '@drift-labs/react';

import { UiVaultConfig } from 'src/@types/vaults';
import { twMerge } from 'tailwind-merge';
import { useMemo } from 'react';
import { HighRiskChip } from '../Chips/HighRiskChip';
import { Typo } from '../Text/Typo';

export const VaultTags = (props: {
	uiVaultConfig: UiVaultConfig;
	numOfVaultSnapshots: number;
	classes?: {
		tagText?: string;
	};
}) => {
	const isNew = props.uiVaultConfig.isNew(props.numOfVaultSnapshots);
	const isHighRisk = props.uiVaultConfig.isHighRisk;
	const numberOfTags = useMemo(() => {
		let count = 0;
		if (isNew) count++;
		if (isHighRisk) count++;
		return count;
	}, [isNew, isHighRisk]);

	return (
		<div className="flex flex-col items-start justify-center space-y-1">
			<div className="flex items-center gap-2 flex-wrap">
				<Tag color="default">
					<Typo.B6 className={twMerge('px-0.5', props.classes?.tagText)}>
						{props.uiVaultConfig.vaultManager.name}
					</Typo.B6>
				</Tag>
				{isNew && (
					<Tag color="purple">
						<Typo.B6 className={twMerge('px-0.5', props.classes?.tagText)}>
							New
						</Typo.B6>
					</Tag>
				)}
				{isHighRisk && <HighRiskChip iconOnly={numberOfTags > 1} />}
			</div>
		</div>
	);
};
