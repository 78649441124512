import { createContext, useCallback, useContext } from 'react';
import {
	VaultAccount,
	Vault,
	VAULT_PROGRAM_ID,
	getDriftVaultProgram,
	VaultDepositor,
	VaultDepositorAccount,
} from '@drift-labs/vaults-sdk';
import { useEffect, useRef, useState } from 'react';
import { COMMON_UI_UTILS, MarketId } from '@drift/common';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { MarketType, PublicKey } from '@drift-labs/sdk';
import useCurrentAuthority from 'src/hooks/useCurrentAuthority';
import {
	getSingleVaultStats,
	useVaultsApyReturnsLookup,
} from 'src/hooks/vaults/useSyncVaultStats';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { useVaultsStore } from 'src/stores/vaultsStore/useVaultsStore';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import { UiVaults } from 'src/constants/vaults';

const useSubscribedVault = (
	vaultPubkey?: string
): {
	vaultAccountData: Vault | undefined;
	vaultAccount: VaultAccount | undefined;
} => {
	const connection = useDriftStore((s) => s.connection.current);
	const bulkAccountLoader = useDriftStore((s) => s.connection.accountLoader);

	const [vaultAccountData, setVaultAccountData] = useState<Vault | undefined>();
	const [vaultAccount, setVaultAccount] = useState<VaultAccount | undefined>();
	const vaultAccountRef = useRef<VaultAccount | undefined>(null);

	useEffect(() => {
		if (!vaultPubkey || !connection || !bulkAccountLoader) return;

		syncVaultAccountAndData();

		return () => {
			setVaultAccount(undefined);
			vaultAccountRef.current?.unsubscribe();
		};
	}, [vaultPubkey, connection, bulkAccountLoader]);

	async function syncVaultAccountAndData() {
		const newWallet = COMMON_UI_UTILS.createThrowawayIWallet();
		const driftVaultsProgram = getDriftVaultProgram(connection, newWallet);

		const vaultAccount = new VaultAccount(
			driftVaultsProgram,
			new PublicKey(vaultPubkey),
			bulkAccountLoader
		);

		setVaultAccount(vaultAccount);
		vaultAccountRef.current = vaultAccount;

		await vaultAccount.subscribe();

		const vaultAccountData = vaultAccount.getData();

		setVaultAccountData(vaultAccountData);

		vaultAccount.eventEmitter.on('vaultUpdate', (newVaultAccountData) => {
			setVaultAccountData(newVaultAccountData);
		});
	}

	return { vaultAccountData, vaultAccount };
};

const useSubscribedVaultDepositor = (
	vaultPubkey?: string
): {
	vaultDepositorAccountData: VaultDepositor | undefined;
	isLoaded: boolean;
} => {
	const authority = useCurrentAuthority();
	const bulkAccountLoader = useDriftStore((s) => s.connection.accountLoader);
	const connection = useDriftStore((s) => s.connection.current);

	const [vaultDepositorAccountData, setVaultDepositorAccountData] = useState<
		VaultDepositor | undefined
	>();
	const [isLoaded, setIsLoaded] = useState(false);

	const vaultDepositorAccountRef = useRef<VaultDepositorAccount | undefined>(
		null
	);

	useEffect(() => {
		if (!authority) {
			setVaultDepositorAccountData(undefined);
			setIsLoaded(true);
		} else {
			setIsLoaded(false);
		}
	}, [authority]);

	useEffect(() => {
		if (!vaultPubkey || !authority || !bulkAccountLoader || !connection) return;

		setIsLoaded(false);

		syncVaultDepositorAccountAndData();

		return () => {
			vaultDepositorAccountRef.current?.unsubscribe();
		};
	}, [vaultPubkey, authority, bulkAccountLoader, connection]);

	async function syncVaultDepositorAccountAndData() {
		const vaultDepositorPubkey = VaultDepositorAccount.getAddressSync(
			VAULT_PROGRAM_ID,
			new PublicKey(vaultPubkey),
			authority
		);
		const newWallet =
			COMMON_UI_UTILS.createThrowawayIWallet(vaultDepositorPubkey);
		const driftVaultsProgram = getDriftVaultProgram(connection, newWallet);

		const vaultDepositorAccount = new VaultDepositorAccount(
			driftVaultsProgram,
			vaultDepositorPubkey,
			bulkAccountLoader
		);
		vaultDepositorAccountRef.current = vaultDepositorAccount;

		await vaultDepositorAccount.subscribe();

		try {
			const vaultDepositorAccountData = vaultDepositorAccount.getData();
			setVaultDepositorAccountData(vaultDepositorAccountData);
		} catch (e) {
			// error thrown above means that the vault depositor is not initialized
		} finally {
			setIsLoaded(true);

			// TODO: check if there is an update when vault depositor is initialized,
			// it should have, otherwise need to force fetch again by exporting this function
			vaultDepositorAccount.eventEmitter.on(
				'vaultDepositorUpdate',
				(newVaultDepositorData) => {
					setVaultDepositorAccountData(newVaultDepositorData);
				}
			);
		}
	}

	return {
		vaultDepositorAccountData,
		isLoaded,
	};
};

type VaultAccountContextType = {
	vaultAccountData: Vault | undefined;
	vaultAccount: VaultAccount | undefined;
	vaultDepositorAccountData: VaultDepositor | undefined;
	isVaultDepositorLoaded: boolean;
	syncVaultStats: () => Promise<void>;
};

const VaultAccountContext = createContext<VaultAccountContextType>({
	vaultAccountData: undefined,
	vaultAccount: undefined,
	vaultDepositorAccountData: undefined,
	isVaultDepositorLoaded: false,
	syncVaultStats: () => Promise.resolve(),
});

/**
 * Stores the current UI-visible vault's subscribed accounts and their data.
 *
 * The difference between this context and `useVaultsStore` is that this updates in real-time, whereas `useVaultsStore` updates when the oracle price stores updates.
 */
export const VaultProvider = (props: {
	children: React.ReactNode;
	vaultPubkey: string;
}) => {
	const { children, vaultPubkey } = props;
	const uiVaultConfig = UiVaults.getVaultConfig(vaultPubkey);

	const setDriftStore = useDriftStore((s) => s.set);
	const isVaultDepositorFormModalOpen = useDriftStore(
		(s) => !!s.modals.showVaultDepositWithdrawFormModal
	);
	const vaultClient = useVaultsStore((s) => s.vaultClient);
	const setVaultsStore = useVaultsStore((s) => s.set);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const getOraclePriceForMarket = useGetOraclePriceForMarket();
	const oraclePrice = getOraclePriceForMarket(
		new MarketId(uiVaultConfig?.depositAsset ?? 0, MarketType.SPOT)
	);
	const memoizedOraclePriceGetter = useCallback(
		(_marketId: MarketId) => oraclePrice,
		[oraclePrice.toNum()]
	);

	const apyReturnsLookup = useVaultsApyReturnsLookup();
	const { vaultAccountData, vaultAccount } = useSubscribedVault(vaultPubkey);
	const { vaultDepositorAccountData, isLoaded: isVaultDepositorLoaded } =
		useSubscribedVaultDepositor(vaultPubkey);

	const syncVaultStats = useCallback(async () => {
		if (
			vaultPubkey &&
			driftClientIsReady &&
			driftClient &&
			vaultClient &&
			apyReturnsLookup[vaultPubkey]
		) {
			const vaultStats = await getSingleVaultStats(
				driftClient,
				vaultClient,
				new PublicKey(vaultPubkey),
				apyReturnsLookup[vaultPubkey],
				memoizedOraclePriceGetter
			);

			setVaultsStore((s) => {
				s.vaultsStats[vaultPubkey] = vaultStats;
			});
		}
	}, [
		vaultPubkey,
		vaultClient,
		apyReturnsLookup[vaultPubkey],
		memoizedOraclePriceGetter,
		driftClientIsReady,
		driftClient,
	]);

	// syncs vault deposit/withdraw modal props
	useEffect(() => {
		if (!isVaultDepositorFormModalOpen) return;

		setDriftStore((s) => {
			s.modalsProps.showVaultDepositWithdrawFormModal = {
				...s.modalsProps.showVaultDepositWithdrawFormModal,
				vaultDepositorAccountData,
				isVaultDepositorLoaded,
				vaultAccountData,
			};
		});
	}, [
		vaultAccountData,
		vaultDepositorAccountData,
		isVaultDepositorLoaded,
		isVaultDepositorFormModalOpen,
	]);

	// syncs vault stats and updates the callback in the vaults store
	useEffect(() => {
		syncVaultStats();

		setVaultsStore((s) => {
			s.syncCurrentPageVaultStats = syncVaultStats;
		});
	}, [syncVaultStats]);

	return (
		<VaultAccountContext
			value={{
				vaultAccountData,
				vaultAccount,
				vaultDepositorAccountData,
				isVaultDepositorLoaded,
				syncVaultStats,
			}}
		>
			{children}
		</VaultAccountContext>
	);
};

export const useVaultContext = () => {
	const context = useContext(VaultAccountContext);

	if (!context) {
		throw new Error('useVaultContext must be used within a VaultProvider');
	}

	return context;
};
