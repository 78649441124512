import { SerializedVaultDepositorRecord } from 'src/db/schema/vault-depositor-records';
import useCurrentAuthority from '../useCurrentAuthority';
import { GET_API_ROUTE } from 'src/constants/api';
import useSWR from 'swr';

const DEFAULT_VAULT_DEPOSITOR_HISTORY: SerializedVaultDepositorRecord[] = [];

export const useVaultDepositorHistory = (vaultPubkey: string) => {
	const authority = useCurrentAuthority();
	const {
		data: vaultDepositorHistory,
		isLoading: isVaultDepositorHistoryLoading,
	} = useSWR<SerializedVaultDepositorRecord[]>(
		authority?.toString() && vaultPubkey
			? `${GET_API_ROUTE(
					'vault-depositor'
			  )}?depositorAuthority=${authority.toString()}&vault=${vaultPubkey}`
			: null,

		{
			fallbackData: DEFAULT_VAULT_DEPOSITOR_HISTORY,
		}
	);

	return {
		vaultDepositorHistory,
		isVaultDepositorHistoryLoading,
	};
};
